import { ProfileTabName } from '@graphql/generated/types/graphqlTypes';
import { Route } from '@libs/routes';

export const csRoute: { [key: string]: string } = {
  [Route.b2bAdminAboutUs]: '/company-admin/about-us',
  [Route.b2bAdminGraphsReport]: '/company-admin/graphs-report',
  [Route.b2bAdminMonthlyReport]: '/company-admin/monthly-report',
  [Route.b2bAdminVisualContent]: '/company-admin/visual-content',
  [Route.companyProfile]: '/nazory-na-zamestnavatele',
  [Route.companyGroup]: '/nazory-na-skupinu',
  [Route.interestingProfileGallery]: '/sledujte-zajimave-firmy',
  [Route.introduction]: '/k-cemu-je-to-dobre',
  [Route.jobDescriptionList]: '/prehled-pracovnich-pozic',
  [Route.jobDescriptionDetail]: '/nabidka-prace',
  [Route.jobApplication]: '/odpovedet-na-nabidku',
  [Route.jobApplicationSent]: '/odpovedet-na-nabidku-odeslana',
  [Route.loginWithPassword]: '/prihlaseni',
  [Route.profileMatching]: '/co-preferuji',
  [Route.profileMatchingResult]: '/co-preferuji/vysledky',
  [Route.registration]: '/registrace',
  [Route.resetPassword]: '/zapomenute-heslo',
  [Route.setPassword]: '/nastaveni-hesla',
  [Route.informationForCompanies]: '/informace-pro-firmy',
  [Route.informationForCompaniesHooray]: '/dekujeme-za-zajem',
  [Route.companyList]: '/seznam-firem',
};

export const csSlugs: { [key: string]: string } = {
  [ProfileTabName.Summary]: '',
  [ProfileTabName.Comments]: '/hodnoceni',
  [ProfileTabName.Atmosphere]: '/statistika',
  [ProfileTabName.JobOpportunities]: '/nabidky-prace',
  [ProfileTabName.Gallery]: '/galerie',
  [ProfileTabName.ProfileShowcase]: '/medailonky',
};
